<template>
  <RealisasiKpiKorporatForm mode="Detail" module="Realisasi Indikator Kinerja"> </RealisasiKpiKorporatForm>
</template>

<script>
import RealisasiKpiKorporatForm from './form-realisasi';

const RealisasiKpiKorporatDetail = {
  name: 'RealisasiKpiKorporatDetail',
  components: { RealisasiKpiKorporatForm },
};

export default RealisasiKpiKorporatDetail;
</script>
